@import "src/sharedComponents/common/variables";

.item-container {
    display: flex;
    align-items: flex-end;
    input {
        min-width: 200px;
    }
    svg{
        width: 12px;
        height: 12px;
        margin-left: 10px;
        cursor: pointer;
        color: #BFBFBF;
    }

    &.editing{
        svg{
            width: 18px;
            height: 18px;
        }
    }
}