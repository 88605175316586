@import "src/sharedComponents/common/variables";

.groupTab-details {
    position: relative;
    z-index: 1; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 200px;

    &--open {
        color: $color-green;
    }
    &--closed {
        color: $color-grey-10;
    }
    &__button--email {
        position: absolute;
        right: 24px;
        top: 20px;
        border-radius: 8px;
    }
}