@import "src/sharedComponents/common/variables";

.button-element {
    background-color: $color-grey-9;
    padding: 7px 16px;
    color: $color-grey-1;
    border-radius: $border-radius-small;
    line-height: 18px;
    cursor: pointer;
    font-weight: $font-weight-semi-bold;

    display: inline-flex;
    align-items: center;

    &--icon {
        margin-right: 4px;
        display: inline-flex;
    }
    .text {
        color: $color-grey-1;
        font-weight: $font-weight-semi-bold;
    }
    &:hover {
        background-color: $color-grey-8;
    }
    &:active {
        background-color: $color-black;
    }
    &.disabled {
        background-color: $color-grey-4;
        color: $color-grey-7;
        .text {
            color: $color-grey-7;
        }
        svg path {
            stroke: $color-grey-7 !important;
        }
    }
}