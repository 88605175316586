@import "src/sharedComponents/common/variables";

.info-section {
  max-width: 480px;
  min-width: 180px;
  width: 100%;

  .item {

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    .item-label {
      color: $color-grey-6;
    }

    .item-value {
      color: $color-grey-9;
    }
  }


  &__title {
    margin-bottom: 24px;
  }

  &__info {
    .item-label {
      color: $color-grey-6;
      font-size: $font-body;
    }

    .item-value {
      color: $color-grey-9;
      font-size: $font-title;
    }

    margin-bottom: 10px;
  }

  &__row {
    display: flex;
    font-size: $font-body;


    &__left-panel {
      flex: 1;
      margin-right: $spacing-component;
    }

    &__right-panel {
      flex: 1; 
      margin-left: $spacing-component;
    }
  }
}