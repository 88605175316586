@import "src/sharedComponents/common/variables";

.item {
  &--credit {
    .item-value {
      color: #ABABB0;
    }
  }

  &--error {
    .item-value {
      color: $color-red;
    }
  }

  &--active {
    .item-value {
      color: $color-blue-1;
    }
  }
}