@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.autocomplete {
    width: 100%;

    &__options {
        color: $color-grey-7;
        div.ant-select-item-option-active:not(.ant-select-item-option-disabled) & {
            color: $color-blue-1;
        }

        &__value {
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            color: $color-grey-8;

            @include small-size {
                width: initial;
            }

            svg {
                margin-left: 4px;
                width: 16px;
                height: 16px;
            }

            .text.header {
                text-transform: none;
            }
        }

        &__dot {
            margin: 0 4px;
            color: $color-grey-7;
        }
    }
    
    &__dorpdown {
        min-width: 450px !important;

        div.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background-color: transparent;
            color: $color-blue-1;
            .autocomplete__options__value {
                color: $color-blue-1;
            }
        }
        div.ant-select-auto-complete .ant-select-clear {
            right: 13px;
        }
        div.ant-select-item-empty {
            height: 104px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
}