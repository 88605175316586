@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.location-groupTabs {
    width: 100%;
    
    &__row {
        &__item-disabled {
            color: $color-grey-5;
        }
    }

    &__inputs {
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        width: 100%;
    }

    &__filter:not(:first-child) {
        margin-left: 14px;

        @include medium-size {
            margin-left: 7px;
        }
    }

    &__input {
        flex: 1;

        &.input-element {
            min-width: 135px;
        }

        &.date-picker {
            min-width: 225px;
            max-width: fit-content;

            @include medium-size {
                min-width: fit-content;
                margin-left: 0px;
            }
        }

        &.radio-buttons {
            flex-basis: 100%;
        }
    }
}