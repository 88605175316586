@import "src/sharedComponents/common/variables";

.bill-split {
    flex-direction: column;

    .highlight{
        background-color: transparent;
        .text {
            color: $color-grey-9;
            font-weight: $font-weight-semi-bold;
        }
    }
}