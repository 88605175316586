.loader{
    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &--overlay {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background: rgba(255, 255, 255, 0.9);
            z-index: 1;
        }
    }
}
