@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.content {
    display: flex;
    min-width: 400px;
    max-height: 100%;
    margin: 0 $horizontal-content-spacing;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--left-panel {
        flex: 5;
        flex-shrink: 1;
        padding: $spacing-component;
        padding-top: $top-content-spacing;
        min-width: min-content;
        max-width: 480px;
        overflow-x: hidden;
        overflow-y: auto;
        @include hide-scroll-bar;

        & > * {
            margin-bottom: $spacing-component;
        }

        @include medium-size{
            flex: auto;
            margin-right: 0;
            max-width: initial;
        }
    }

    &--right-panel {
        flex: 7;
        flex-shrink: 1;
        padding: $spacing-component;
        padding-top: $top-content-spacing;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        min-width: min-content;
        @include hide-scroll-bar;

        & > * {
            margin-bottom: $spacing-component;
        }

        @include medium-size{
            flex: auto;
            margin-left: 0;
            padding-top: 0;
            max-width: initial;
        }
    }

    @include medium-size{
        flex-direction: column;
        min-width: 300px;
    }
}