@import "src/sharedComponents/common/variables";

.order-details {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 200px;

    p::first-letter {
        text-transform: capitalize;
    }
    
    .low {
        color: $color-grey-7;
    }

    .success {
        color: $color-green;
    }

    .failure {
        color: $color-red;
    }

    .warning {
        color: $color-orange;
    }

    .button-email {
        position: absolute;
        right: 24px;
        top: 20px;
        border-radius: 8px;
    }
}
