
@mixin hide-scroll-bar {
    -webkit-overflow-scrolling: touch !important;
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

@mixin medium-size($override: "max-width: 900px") {
    @media only screen and ($override) {
        @content;
    }
}
@mixin small-size($override: "max-width: 670px") {
    @media only screen and ($override) {
        @content;
    }
}
@mixin svg-color($color) {
    svg:not(.multi-color) {
        &:not([fill="none"]) {
            &:not([stroke]), &[stroke="none"] {
                fill: $color;
            }
        }

        *[fill]:not([fill="none"]):not(.ignore-svg-color) {
            fill: $color !important;
        }

        *[stroke]:not([stroke="none"]):not(.ignore-svg-color) {
            stroke: $color !important;
        }
    }
}
