@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/assets/fonts.scss";

.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: $font-weight-normal;
    color: currentColor;
    display: inline-flex;

    &.title {
        font-weight: $font-weight-semi-bold;
        font-size: $font-title;
        line-height: $line-height-title;
    }
    
    &.subtitle {
        font-size: $font-subtitle;
        line-height: $line-height-subtitle;
    }

    &.info-title {
        font-weight: $font-weight-semi-bold;
        font-size: $font-header;
        line-height: $line-height-header;
        letter-spacing: .04em;
        color: $color-grey-6;
        text-transform: uppercase;
    }

    &.body {
        font-size: $font-body;
        line-height: $line-height-body;
        
        &.disabled {
            color: $color-grey-5;
        }
    }

    &.header {
        font-size: $font-header;
        line-height: $line-height-header;
        color: inherit;
        letter-spacing: .04em;
        text-transform: uppercase;
        font-weight: $font-weight-semi-bold;

        &.disabled {
            color: $color-grey-6;
        }
    }

    &.mode {
        &--bold {
            font-weight: $font-weight-semi-bold;
        }

        &--label {
            color: $color-grey-6;
        }

        &--disabled {
            color: $color-grey-4;
        }

        &--link {
            color: $color-blue-1;
            >a {
                color: $color-blue-1;
            }
        }
        
        &--block {
            display: block;
        }
    }
}