@import "src/sharedComponents/common/variables";

.page {
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
        flex: 1;
        background-color: $color-grey-2;
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
    }
}