@import "src/sharedComponents/common/variables";

.section { 
    background: $color-grey-1;
    box-shadow:  0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-normal;
    overflow: hidden;

    // using this to enable content to override color, created for rawJson dark mode
    &>* {
        padding: 0 24px;
        &:first-child {
            padding-top: 24px;
        }
        &:last-child {
            padding-bottom: 24px;
        }
    }
}