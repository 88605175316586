@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.row-order-id {
    display: flex;
    align-items: center;
    transform: translateZ(10px);
    
    svg {
        min-width: 14px;
    } 
    &.warning {
        @include svg-color($color-orange);
    }
    &.error {
        @include svg-color($color-red);
    }

    &__icon {
        display: flex;
        align-items: center;
    }

    &__order {
        margin-left: 6px;
    }

    &__tooltip {
        .ant-tooltip-inner::first-letter {
            text-transform: capitalize;
        }
    }
}