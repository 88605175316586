@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

input.input-element, span.input-element  {
    border-radius: $border-radius-normal;
    -webkit-appearance: none;
    outline: none; 
    appearance: none;

    background: $color-grey-1;
    border-color: $color-grey-6;
    font-size: $font-body;
    line-height: $line-height-default;
    color: $color-grey-9;

    //override ant style
    width: initial;
    position: initial;
    box-shadow: initial;

    span.ant-input-focused {
        box-shadow: none;
        border-color: $color-blue-1;
    }
    
    .ant-input-group {
        span.ant-input-group-addon:last-child .ant-input-search-button {
            border-top-right-radius: $border-radius-normal;
            border-bottom-right-radius: $border-radius-normal;
            border-left: 0;
            box-shadow: none;
            &::after {
                display: none;
            }
        }
        span.ant-input-affix-wrapper:not(:last-child) {
            border-top-left-radius: $border-radius-normal;
            border-bottom-left-radius: $border-radius-normal;
            border-right: 0;
            box-shadow: none;
            border-color: $color-grey-6;
            
            ~span.ant-input-group-addon .ant-input-search-button {
                border-color: $color-grey-6;
            }
        }
    }

    &.filled:not(:active):not(:focus):not(:hover), &.filled.ant-input-search:not(:active):not(:focus):not(:hover) {
        border-color: $color-grey-5;
        box-shadow: initial;
        span.ant-input-affix-wrapper {
            border-color: $color-grey-5;

            &-focused {
                border-color: $color-blue-1;
                ~span.ant-input-group-addon .ant-input-search-button {
                    border-color: $color-blue-1;
                }
            }
        }
        span.ant-input-group-addon .ant-input-search-button {
            border-color: $color-grey-5;
        }
        
    }

    &:active, &:focus, &:hover {
        border-color: $color-blue-2;
        .ant-input-group span.ant-input-affix-wrapper:not(:last-child) {
            border-color: $color-blue-2;
            ~span.ant-input-group-addon .ant-input-search-button {
                border-color: $color-blue-2;
            }
        }
    }

    &.error {
        border-color: $color-red !important;
        color: $color-red !important;
        span.ant-input-affix-wrapper {
            border-color: $color-red !important;
        }
        span.ant-input-group-addon .ant-input-search-button {
            border-color: $color-red !important;
        }
    }
}

.input-error-message.text {
    color: $color-red;
    padding-top: 5px;
}