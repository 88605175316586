@import "src/sharedComponents/common/variables";

.mnu-modal {
    .ant-modal-content {
        background-color: transparent;
        border-radius: $border-radius-normal;
        overflow: hidden;

        .ant-modal-body {
            padding: 0;
            background-color: $color-grey-1;

            > * {
                padding: 0 $double-spacing-component;
                &:first-child {
                    padding-top: $double-spacing-component;
                }
                &:last-child {
                    padding-bottom: $double-spacing-component;
                }
            }
        }
    }
}