@import "src/sharedComponents/common/variables";

.json-viewer {
    transition: background-color 0.3s ease;
  .text.info-title {
    display: block;
    margin-bottom: 0;
  }

  .ant-collapse {
    background-color: transparent;
    border: none;

    &-item {
      border-bottom: none;
    }

    &-content {
      border-top: none;
      position: relative;
        .dark-mode {
            position: absolute;
            display: flex;
            align-items: center;
            right: 0;
            z-index: 1;
            font-size: $font-tag;
            line-height: $line-height-tag;
        }
        .react-json-view {
            background-color: transparent !important;
        }
      &-box {
        padding: 0;
        margin-bottom: 20px;

        pre {
          margin-bottom: 0;
        }
      }
    }
  }

  &__label {
    cursor: pointer;

    .text.body {
      color: $color-blue-1;
      font-weight: $font-weight-semi-bold;
    }
  }
  &.dark {
      background-color: $color-grey-9;
      .dark-mode {
        color: $color-grey-4
      }
  }
}