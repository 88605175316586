@import "src/sharedComponents/common/variables";

.tables {
    width: 100%;
  &__title {
    padding-left: 16px;
    margin-bottom: 24px;
  }
  
  .ant-table-thead {
    .ant-table-cell {
      font-weight:  $font-weight-semi-bold;
      font-size: $font-body;
      line-height: $line-height-body;
      color: $color-grey-9;
      background-color: transparent;
      border-bottom: none;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
        cursor: pointer;
        font-size: $font-body;
        line-height: $line-height-body;
        color: $color-grey-8;
        border-bottom: none;
    }
  }

  .cell-border {
    .ant-table-tbody {
      .ant-table-cell {
        border-bottom: 0.5px solid $color-grey-4;

      }
    }
  }

  .ant-table-cell {
    &.last-column {
      font-weight:  $font-weight-semi-bold;
      color: $color-grey-9;
      text-align: right;
    }
  }

  .ant-table-footer {
    background-color: transparent;
  }
  .highlight {
      background: $color-grey-2;
      .ant-table-cell {
          color: $color-grey-9;
          font-weight: $font-weight-semi-bold;// !important;
      }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    color: $color-grey-9;
    font-size: $font-body;
    font-weight: $font-weight-semi-bold;
  }
}