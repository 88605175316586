/* Colours */
$color-business:#7B8DAF;         /* me&u color */
$color-black:   #000000;         /* Black */
$color-grey-1:  #FFFFFF;         /* Grey / grey-1 */
$color-grey-2:  #FAFAFA;         /* Grey / grey-2 */
$color-grey-3:  #F5F5F5;         /* Grey / grey-3 */
$color-grey-4:  #E8E8E8;         /* Grey / grey-4 */
$color-grey-5:  #D9D9D9;         /* Grey / grey-5 */
$color-grey-6:  #BFBFBF;         /* Grey / grey-6 */
$color-grey-7:  #8C8C8C;         /* Grey / grey-7 */
$color-grey-8:  #595959;         /* Grey / grey-8 */
$color-grey-9:  #262626;         /* Grey / grey-9 */
$color-grey-10: #ABABB0;         /* Grey / grey-10 */
$color-blue-1:  #597EF7;         /* Geek Blue / geekblue-5 */
$color-blue-2:  #85A5FF;         /* Geek Blue / geekblue-4 */
$color-red:     #F5222D;         /* Dust Red / red-6 */
$color-green:   #52C41A;         /* Polar Green / green-6 */
$color-orange:  #FA8C16;         /* Sunset Orange / orange-6 */

/* Font-sizes */
$font-tag: 8px;
$font-body: 12px;
$font-header: 14px;
$font-subtitle: 16px;
$font-title: 18px;

/* Line-heights */
$line-height-default: 183%;
$line-height-tag: 13px;
$line-height-body: 18px;
$line-height-header: 22px;
$line-height-subtitle: 20px;
$line-height-title: 22px;

/* Font-weight */

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/* Spacing */
$spacing-xs: 4px;
$spacing-small: 8px;
$spacing-component: 12px;
$spacing-title: 18px;
$double-spacing-component: 24px;
$spacing-big-header: 32px;
$horizontal-content-spacing: 8.3vw;
$top-content-spacing: 100px;

/* Border-Radius */
$border-radius-small: 2px;
$border-radius-normal: 4px;
$border-radius-large: 8px;

// module.exports = {
// // :export {
//     colors: {
//         business: $color-business;
//         background: $color-grey1;
//         border: $color-grey-5;
//         empty-border: $color-grey-6;
//         text: $color-grey-9;
//         active: $color-blue-1;
//         error: $color-red;
//     }
//   }