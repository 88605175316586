@import "src/sharedComponents/common/variables";

div.date-range  {
    border-radius: $border-radius-normal;
    -webkit-appearance: none;
    outline: none; 
    appearance: none;

    border: 1px solid $color-grey-6;
    padding: 5px 16px;
    background: $color-grey-1;

    font-size: $font-body;
    line-height: $line-height-default;

    max-width: 240px;
    min-width: 200px;
    &:active, &:focus, &:hover {
        border-color: $color-blue-2;
        box-shadow: initial;
    }
    &::after {
        display: none;
    }
    &.ant-picker {
        &-focused {
            border-color: $color-blue-1;
            border-right-width: 1px !important;
            box-shadow: initial;
        }
    }
    input {
        font-size: $font-body;
        line-height: $line-height-default;
    }
    .ant-picker-clear {
        right: 16px;
    }
}