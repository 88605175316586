@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.row-groupTab-name {
    display: flex;
    align-items: center;
    transform: translateZ(10px);
    
    svg {
        min-width: 14px;
    } 

    &__icon {
        display: flex;
        align-items: center;
    }

    &__tabName {
        margin-left: 6px;
    }

    &__tooltip {
        .ant-tooltip-inner::first-letter {
            text-transform: capitalize;
        }
    }
}