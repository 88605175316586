@import "src/sharedComponents/common/variables";

.location-parties {
    width: 100%;

    &--row {
        &--item-disabled {
            color: $color-grey-5;
        }
    }

    &--inputs {
        display: flex;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    &--filter:not(:first-child) {
        margin-left: 14px;
    }

    &--input {
        flex: 1;
        &.input-element {
            min-width: 130px;
        }
    }

    &--date-range {
        min-width: 230px !important; // dont blame me, blame ant
    }
}
