@import "src/sharedComponents/common/variables";

.tabs {
    background-color: $color-grey-1;
    .ant-tabs-bar {
        border: none;
        background-color: $color-grey-1;
        margin: -$double-spacing-component;
        margin-bottom: 0;
        padding: $double-spacing-component;
        padding-bottom: 0;
    }
    div.ant-tabs-tab {
        padding-bottom: $double-spacing-component;
        padding-top: 0;
        color: $color-grey-8;

        &:not(.ant-tabs-tab-disabled) {
            &.ant-tabs-tab-active, &:hover {
                &>.text {
                    color: $color-blue-1;
                }
            }
        }
        &.ant-tabs-tab-disabled {
            &>.text {
                color: $color-grey-4;
            }
        }
    }
    div.ant-tabs-content {
        margin-top: $double-spacing-component;
    }
}