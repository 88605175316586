@import "src/sharedComponents/common/variables";

.search {
    &__body {
        margin-top: -$double-spacing-component;
        padding: $spacing-small;
        color: $color-grey-8;
    }

    &__title {
        color: $color-grey-9;
    }

    &__description {
        padding-top: $spacing-xs;
        padding-bottom: $double-spacing-component;
    }

    &__by {
        padding-bottom: $spacing-small;
    }

    &__inputs {
        &__two {
            display: flex;
        }
        
        &__left {
            margin-right: $spacing-small;
        }

        &__right {
            min-width: 275px;
        }
    }

    &__additional {
        padding-top: $spacing-big-header;
        padding-bottom: $spacing-small;
    }
}

.search-open .rc-virtual-list-scrollbar {
    display: block !important;
}