@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.mnu-dropdown {
    min-width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $border-radius-normal;

    color: $color-grey-6;
    span {
        color: $color-grey-8;
    }
    svg {
        transition: transform 250ms ease-in-out;
    }

    &:active, &:focus, &:hover {
        border-color: $color-blue-2;
        box-shadow: initial;
    }
    &::after {
        display: none;
    }
    &.ant-dropdown-open {
        border-color: $color-blue-1;
        box-shadow: initial;
        svg {
            transform: rotate(-180deg);
        }
    }
}