@import "src/sharedComponents/common/variables";

.region-selection {
    animation: slow-show 100ms 500ms ease forwards;
    opacity: 0;

    &__options {
        margin-right: 20px;
        height: 100%;
    }

    &__denied {
        color: $color-red;
    }
}

@keyframes slow-show {
    to {
        opacity: 1;
    }
}