@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.groupTab-order-items {
    position: relative;
    z-index: 1; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 200px;
    
    &__header {
        margin-bottom: $double-spacing-component;
    }

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;

        &__member {
            min-width: 140px;
            width: 100%;
            margin-left: $spacing-component;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        &__price {
            width: 64px;
            overflow: hidden;
            margin-left: $spacing-component;
            display: inline-block;
            text-align: right;
        }

        &__menuType {
            margin-left: 12px;
        }

        &--center {
            display: flex;
            
            .info {
                display: flex;
                flex-direction: column;
            }   
        }

        &--right {
            display: flex;
            flex-direction: row-reverse;
        }

        svg {
            min-width: 14px;
        }
    }
}