@import "src/sharedComponents/common/variables";

.key-value-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $color-grey-8;

    &.mild {
        .text{
            color: $color-grey-7;
        }
    }

    &.emphasis {
        .text {
            font-weight: $font-weight-semi-bold;
        }
    }
    
    &.red {
        .text {
            color: $color-red;
        }
    }
    
    &.green {
        .text {
            color: $color-green;
        }
    }

    .item-label {

    }
    .item-value {

    }
}