@import "src/sharedComponents/common/variables";

div.breadcrumbs {
    padding: 56px calc(8.3vw + 12px) 12px;
    padding-top: 56px;
    position: relative;
    color: $color-grey-8;
    background-color: $color-grey-2;
    z-index: 1;
}
