@import "src/sharedComponents/common/variables";

.groupTab-payment {
    margin-top: $spacing-component;
    
    .key-value-row {
        margin-bottom: $spacing-title;
    }

    &__title {
        margin-bottom: $spacing-title;
    }
}