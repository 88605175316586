.section.location-page {
    &--cms-venue {
        text-transform: uppercase;
        display: flex;
        
        a {
            padding-top: 0;
            padding-bottom: 0;
            margin: 24px 0;
            display: inline-flex;
            align-items: center;
        }
        
    }
}