@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.nav {
    display: flex;
    justify-content: space-between;
    padding: 30px $horizontal-content-spacing;
    align-items: center;
    min-width: fit-content;
    width: initial;
    background: white;
    border-bottom: 1px solid $color-grey-4;

    @include small-size {
        padding: 15px $horizontal-content-spacing;
    }

    &__section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        
        &:first-child {
            flex: 5
        }

        @include small-size {
            flex-direction: column;
        }
    }
    
    &__icon {
        margin-right: 35px;

        @include small-size {
            display: none;
        }
    }

    &__search-field {
        margin-right: 25px;
        width: 100%;
        max-width: 650px;
        min-width: 200px;
    }

    &__radios {
        @include medium-size {
            display: flex;
            flex-direction: column;

            @include small-size { 
                flex-direction: row;
            }
        }
    }
    &__main-action {
        display: flex;
        max-height: 40px;
        
        .disabled {
            cursor: not-allowed;
        }
    }

    &__option {
        min-width: fit-content;
        margin-left: 32px;

        @include medium-size {
            &:not(:last-child) {
                display: none;
            }
        }

        & .text.info-title {
            transition: color ease 350ms;
            color: $color-grey-8;
        }

        & .text.info-title:hover {
            transition: color ease 350ms;
            color: $color-grey-9;
        }

        &:hover {
            cursor: pointer;
        }

        &:not(:last-of-type) {
            margin-right: 2%;
        }
    }

    .button-element {
        background-color: $color-black;
        padding: 8px 16px;
        border-radius: $border-radius-large;

        &--icon {
            margin-right: 8px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        .text {
            font-size: $font-header;
            line-height: 22px;
            text-transform: uppercase;
        }
    }
}