@import "src/sharedComponents/common/variables";

.groupTab-transaction {
    margin-top: $spacing-component;

    &__title {
        margin-bottom: $spacing-title;
    }
    .key-value-row {
        margin-bottom: $spacing-title;
    }
    &.failure .text {
        color: $color-red;
    }
    &.failure :link {
        color: $color-red;
    }
}